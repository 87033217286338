.margin-btn-div {
  margin: 20px 20px 20px 0 !important;
}
/*******for grid table*****/
.userrole-table-margin {
  margin: 15px 8px;
}
.userrole-table-margin .MuiDataGrid-columnHeaders {
  min-height: 35px !important;
  max-height: 35px !important;
  line-height: 35px !important;
}
.userrole-table-margin .MuiDataGrid-row .MuiDataGrid-cell {
  min-height: 35px !important;
  max-height: 35px !important;
  line-height: 35px !important;
}
/* .userrole-table-margin .MuiDataGrid-cell {
  min-height: 35px !important;
  max-height: 35px !important;
  line-height: 35px !important;
} */

/*******for user edit form*****/
.user-role-form {
  margin: 12px 25px !important;
}
.user-role-form .MuiGrid-root {
  margin: 6px 1px;
}
.user-role-form .MuiButton-root {
  font-size: 14px;
  padding: 5px 12px;
  line-height: 1.8;
}
.user-role-form .MuiButton-root:hover {
  background-color: #2b8818;
  color: #fff !important;
}
.user-role-form .MuiOutlinedInput-root,
.user-role-form .MuiInputBase-input {
  padding: 6px 4px !important;
}
