#leasingActivity {
  border-collapse: collapse;
  width: 100%;
}

#leasingActivity td, #leasingActivity th {
  border: 1px solid #ddd;
  padding: 2px;
}

#leasingActivity tr:nth-child(even){background-color: #f2f2f2;}

/* #customers tr:hover {background-color: #ddd;} */

#leasingActivity th {
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  background-color: #253746;
  color: white;
  height: 30px;
  line-height: 30px;
}
#leasingActivity td {
  text-align: left;
  height: 20px;
  line-height: 20px;
}