.App {
  text-align: center;
}

.App-logo {
  width: 150px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.downloadLink:hover {
  text-decoration: underline;
  /* color: #3b6386; */
  color: #0c4501;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.doc-section-width {
  width: 100% !important;
}

.navbar {
  overflow: hidden;
  background-color: #a1a2a3;
  max-height: 40px;
  padding-left: 24px;
  padding-right: 24px;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: #333;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  line-height: 11.5px;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #333;
  padding: 18px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  border-radius: 0px;
  line-height: 5px;
}

.dropdown .dropbtn-selected {
  background-color: #2b8818;
  color: #fff;
}

.navbar a:hover,
.dropdown:hover {
  background-color: #2b8818;
  color: #fff;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #2b8818;
  color: #fff;
}

.navbar .active {
  background-color: #2b8818;
  color: #fff;
}

.navbar .active:hover {
  background-color: #2b8818;
  color: #fff;
}

.navbar-selected {
  background-color: #2b8818;
  color: #fff;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: -1px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  cursor: pointer;
  color: #333;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown .dropbtn:active {
  background-color: #2b8818;
  color: #fff;
}

html,
body {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
  background-color: #f7f7f7 !important;
}

#root {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}

.content {
  /* min-height: calc(50vh - 50px);   */
  height: auto;
  padding: 40px 40px 0 40px;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  height: 45px;
  background-color: #253746;
  color: #fff;
  padding: 14px;
  width: 100%;
  margin-top: auto;
}

.main-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiContainer-root {
  margin-bottom: 16px;
}

.dashboard-table-wrapper {
  height: auto !important;
}

.header {
  background-color: #253746;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-left: 24px;
  padding-right: 24px;
}

.logo {
  display: inline-block;
  vertical-align: bottom;
  width: 150px;
  height: 32px;
  background: url("./nuveen-logo.svg") top left no-repeat;
  background-size: 135px 32px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.app-title {
  font-size: 26px;
  font-weight: 600;
  /* float: left; */
  display: inline-block;
  padding: 10px 0px;
  margin: 0;
  color: #fff;
}

/* Style the active/current link*/
.header a.active {
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

.header-right button {
  margin-right: auto;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  /* .header-right {
    float: none;
  } */
}

/* for select dropdown */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 0.8em;
  padding: 10px 14px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px;
}

.css-111avd1-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 10px 14px;
}

.css-vjtuzh-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}

.title {
  display: block;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
}

.title1 {
  display: block;
  text-align: right;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
}

.wrapper {
  width: 100%;
  display: block;
  /* background-color: #eee; */
  padding: 4px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.wrapper1 {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.txt {
  font-size: 14px;
  margin: 0px 0px 4px 0px;
}

/* for breadcrums */
/* a.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-filledDefault.css-1oohpub-MuiChip-root {
  background-color: #253746;
  color: #fff;
} */
.brd a {
  background-color: transparent;
  color: #666;
}

.hm {
  color: #666;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorDefault.css-ptiqhd-MuiSvgIcon-root{
color:#fff;
} */
/* image gallery css */
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0px !important;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  padding: 8px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 60px !important;
}

button.image-gallery-icon.image-gallery-right-nav:hover {
  background-color: #ccc;
}

button.image-gallery-icon.image-gallery-left-nav:hover {
  background-color: #ccc;
}

button.image-gallery-icon.image-gallery-play-button:hover {
  background-color: #ccc;
}

button.image-gallery-icon.image-gallery-fullscreen-button:hover {
  background-color: #ccc;
}

/* form css */
.MuiFormControl-root.MuiTextField-root.m18 {
  margin-bottom: 18px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  height: 100% !important;
  border: 1px solid #efefef;
  padding: 18px 12px 12px 12px !important;
}

.MuiAvatar-root.MuiAvatar-circular.css-1wlk0hk-MuiAvatar-root {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 34px;
  height: 34px;
}

.css-uym98a-MuiImageList-root {
  width: auto !important;
}

/* form legent */
.lease-form .lease-title {
  text-align: left;
  padding: 10px 14px;
  background-color: #2b8818;
  color: #fff;
  border-radius: 4px;
}

.lease-form fieldset {
  border: 1px solid #2b8818;
  background-color: #fff;
  border-radius: 4px;
  text-align: left;
}

/* form legent */
.mission-form legend {
  text-align: left;
  padding: 10px 14px;
  background-color: #2b8818;
  color: #fff;
  border-radius: 4px;
}

.mission-form fieldset {
  border: 1px solid #2b8818;
  background-color: #fff;
  border-radius: 4px;
  text-align: left;
}

/*table css*/
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 0.875rem;
  background-color: white;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* #customers tr:hover {background-color: #ddd;} */

#customers th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: left;
  background-color: #253746;
  color: white;
  font-weight: normal;
}

#customers td {
  text-align: left;
}

.noneditable-table tr {
  background-color: #f2f2f2;
}

.noneditable tr:hover {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.grey-bg {
  background-color: #ccc !important;
  color: #333 !important;
}

/* new table */
.noneditable-table1 {
  border-collapse: collapse;
  width: 100%;
  background: white;
}

.noneditable-table1 td,
.noneditable-table1 th {
  border: 1px solid #ddd;
  /* padding: 8px; */
}

.noneditable-table1 th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: center;
  background-color: #253746;
  color: white;
}

.noneditable-innertable {
  border-collapse: collapse;
  width: 100%;
}

.noneditable-innertable td,
.noneditable-innertable th {
  border: 1px solid #ddd;
  padding: 8px;
  border-collapse: collapse;
}

.noneditable-innertable th {
  text-align: center;
  background-color: #253746;
  color: white;
}

/* .noneditable-table1 td {
  text-align: left;
} */

/* .full-row{
  background-color: #f2f2f2;
} */
/* image slider */
.image-title {
  font-size: 18px;
  text-align: center;
  margin: 8px auto;
}

.css-ktslay-MuiButtonBase-root-MuiIconButton-root {
  top: 40% !important;
}

.css-1633um2-MuiPaper-root {
  color: #333 !important;
}

/* popup box */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 40%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 40%;
}

.close-btn {
  float: right;
}

.close-btn:hover {
  color: #333;
}

.title-box .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-ah0e1x-MuiPaper-root {
  padding: 0px;
}

h3.tlt {
  background-color: #253746;
  margin: 0px 0px 10px 0px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
}

.fr-box.fr-basic.fr-top {
  padding: 10px;
}

/* common classes */
.form-mb-20 {
  margin-bottom: 20px !important;
}

.primary {
  background-color: #2b8818 !important;
  color: #fff;
}

.primary-outline {
  background-color: #fff;
  color: #2b8818 !important;
  border: 1px solid #2b8818 !important;
}

.primary-outline:hover {
  background-color: #2b8818 !important;
  color: #fff !important;
}

.text-right {
  float: right;
  text-align: right;
}

.number-right {
  text-align: right !important;
}

.tab-center {
  text-align: center;
  margin-bottom: 10px;
}

.text-left {
  float: left;
}

.text-center {
  text-align: center;
}

.clear {
  clear: both;
}

.center {
  margin: 0 auto;
}

.color-red {
  color: red;
}

/* .data-content{
  background-color: #f9f9f9;
} */
.pl-10 {
  padding-left: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-07 {
  margin-top: 7px;
}

.mt-05 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.red {
  color: red;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-20 {
  margin: 20px 0px;
}

.m-07 {
  margin: 7px;
}

.p-10 {
  padding-top: 10px !important;
}

.nm {
  margin: 0;
}

.shift2 {
  padding-left: 40px !important;
}

.shift1 {
  padding-left: 20px !important;
}

.margin-top-bottom {
  margin: 15px 0px;
}

.margin-top-bottom2 {
  margin: 10px 0px 20px 0px;
}

.common-link {
  color: #253746;
  cursor: pointer;
  text-decoration: underline;
}

.select-tab .MuiFormControl-root.css-574lq2-MuiFormControl-root {
  margin-left: 0px;
}

.delete-btn .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  color: #888;
}

.note {
  text-align: left;
  color: #333;
}

.main-tab button {
  border-radius: 40px !important;
  padding: 14px;
  margin: 0px 6px;
  background-color: #ddd;
}

.main-tab .Mui-selected {
  color: #fff !important;
  background-color: #253746 !important;
  font-weight: 700;
}

.main-tab .Mui-selected:hover {
  color: #fff;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected:hover{
  color:#253746!important;
} */
/* .css-1aquho2-MuiTabs-indicator{
  height: 4px!important;
  background-color: transparent!important;
} */

/* .MuiTabs-indicator .css-ttwr4n{
  height: 4px!important;
  background-color: transparent!important;
} */
.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.06);
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.5);
}

/* tabs css */
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:hover {
  background-color: #253746;
}

/* button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border-radius: 40px;
  background-color: #ddd;
  padding: 14px;
  margin: 0px 6px;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 40px;
}

.css-1ujnqem-MuiTabs-root {
  min-height: 45px;
}

/* .css-1gsv261 {
   border-bottom: none;
} 
.MuiBox-root.css-1gsv261 {
  border-bottom: none;
} */
.custom-height input#outlined-basic {
  height: 0.4rem;
}

/* summary table */
.summary-table {
  /* margin-left: auto;
  margin-right: auto; */
  padding: 0px 12px;
}

.summary-table th,
td {
  padding: 5px 2px;
}

.summary-property-section {
  margin-top: 16px;
}

.summary-property-section .text-left {
  padding-left: 12px !important;
}

.min-height div {
  padding: 6px;
}

.min-height1 div {
  padding: 0px !important;
}

.min-height1 {
  margin-bottom: 0px !important;
}

/* for bigger :-moz-full-screen */
/* @media (min-width: 1920px) and (max-width: 2560px)  {
  .css-1ss2ei0-MuiContainer-root {
    max-width: fit-content !important;
}
.css-x3h831 {
  max-width: fit-content !important;
} 
} */

#long-button {
  left: 0px;
  position: absolute;
  top: 0px;
  z-index: 999;
}

.MuiLoadingButton-loadingIndicator {
  color: #f2f2f2 !important;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }

  /* page-break-after works, as well */
}

/**** template style for print pdf css */

#print-pdf-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

#print-pdf-table h2 {
  text-align: center;
  font-size: 18px;
}

#print-pdf-table h3 {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

#print-pdf-table td,
#print-pdf-table th {
  /* border: 1px solid #ddd; */
  padding: 2px;
  font-weight: 400;
  text-align: right !important;
  font-size: 9px;
  line-height: 1.2;
  letter-spacing: 0.01071em;
}

#print-pdf-table th {
  background-color: rgb(37, 55, 70);
  color: white;
}

#print-pdf-table tr:nth-child(even) {
  background-color: #ddd;
}

.row {
  margin: 0;
}

.column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

/* table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
} */

#leasing-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

#leasing-table h2 {
  text-align: center;
  font-size: 18px;
}

#leasing-table h3 {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

.leasing-table-heading h2 {
  text-align: center;
  font-size: 18px;
}

.leasing-table-heading h3 {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

#leasing-table th,
#leasing-table td {
  padding: 5px 10px;
  font-weight: 400;
  text-align: left !important;
  font-size: 11px !important;
  line-height: 1.2;
  letter-spacing: 0.01071em;
  /* width: 80%; */
}

.leasing-table-head th {
  background-color: rgb(37, 55, 70);
  color: white;
}

#leasing-table tr:nth-child(even) {
  background-color: #ddd;
}

.summary-info-table td:nth-child(odd) {
  font-weight: bold;
}

.summary-info-table h2 {
  text-align: center;
  font-size: 18px;
}

.column h3 {
  font-size: 14px;
  margin: 0;
  padding: 2px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

#table-comment {
  border: none;
}

#table-comment {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

#table-comment h2 {
  text-align: center;
  font-size: 16px;
}

#table-comment h3 {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

#table-comment th {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 5px;
  padding-top: 5px;
}

#table-comment td {
  border: 2px solid #efefef;
  padding: 5px;
}

#table-comment p,
#table-comment span,
#table-comment li {
  text-align: left !important;
  font-size: 11px !important;
  color: #000 !important;
  font-weight: 400 !important;
  white-space: normal !important;
}

#table-comment li {
  list-style-type: none;

  &:before {
    content: "• ";
    margin-left: -1em;
  }
}

#table-comment .table-subhead {
  text-align: left !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: bold !important;
}

#print-pdf-table.capital-plan-table-1 th,
#print-pdf-table.capital-plan-table-1 td {
  text-align: left !important;
}

#print-pdf-table.capital-plan-table td:nth-child(1) {
  text-align: left !important;
  padding-left: 20px !important;
  background-color: #ccc !important;
  color: #333 !important;
  border: 1px solid #ddd;
}

.capital-plan-table td {
  background-color: #fff !important;
  text-align: left !important;
}

#financial-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

#financial-table h2 {
  text-align: center;
  font-size: 18px;
}

#financial-table h3 {
  text-align: left;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

#financial-table td,
#financial-table th {
  /* border: 1px solid #ddd; */
  padding: 2px;
  font-weight: 400;
  text-align: right !important;
  font-size: 9px;
  line-height: 1.2;
  letter-spacing: 0.01071em;
}

#financial-table th {
  background-color: rgb(37, 55, 70);
  color: white;
}

#financial-table td:nth-child(1) {
  text-align: left !important;
  padding-left: 5px;
}

#row-main-header {
  background-color: rgb(211, 226, 239);
  cursor: pointer;
}

#row-main-header td {
  font-weight: 600;
}

#row-main-total {
  background-color: rgb(134, 141, 147);
}

#row-main-total td {
  font-weight: 600;
}

#row-sub-header {
  background-color: rgb(242, 242, 242);
}

#row-sub-header td {
  font-weight: 600;
}

#row-sub-total {
  background-color: rgb(204, 204, 204);
}

#row-sub-total td {
  font-weight: 600;
}

#comment-table th {
  background-color: rgb(7, 81, 86);
  border-radius: 4px;
  color: #fff;
  padding: 2px 5px;
  text-align: left;
  font-size: 11px;
}

#comment-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}

#comment-table h2 {
  text-align: center;
  font-size: 18px;
}

#comment-table h3 {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  padding: 2px;
}

#comment-table p,
#comment-table span,
#comment-table li {
  text-align: left !important;
  font-size: 11px !important;
  color: #000 !important;
  font-weight: 400 !important;
  white-space: normal !important;
}

#comment-table td {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pdf-table-heading {
  text-align: center !important;
  font-size: 18px !important;
}

/************Dashboard-styling-******************/
.MuiDataGrid-root .MuiButtonBase-root:hover,
.MuiDataGrid-panelFooter .MuiButtonBase-root:hover {
  background-color: transparent;
  color: #1976d2 !important;
}

.MuiBreadcrumbs-separator {
  margin: 0 !important;
}

.MuiChip-icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiChip-labelMedium {
  padding-left: 8px !important;
  padding-right: 7px !important;
}

.MuiContainer-root .MuiButton-root {
  font-size: 13px;
  padding: 4px 10px;
  line-height: 1.8;
}

.MuiTabs-root .MuiButtonBase-root {
  font-size: 13px;
  padding: 4px 10px;
  line-height: 1.8;
  border-radius: 20px !important;
  min-height: 40px;
}

.tab-heading .heading {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-top: 18px;
  margin-left: 2px;
}

.MuiGrid-root .MuiGrid-item .grid-item-box {
  margin-top: 40px;
}

.MuiGrid-root .MuiPaper-elevation .text-left {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.summary-form .MuiGrid-root .MuiPaper-elevation h4 {
  font-size: 15px;
  line-height: 1;
  margin: 18px 1px;
}

.MuiGrid-root .occupancy-container {
  margin: 0;
}

.occupancy-container .table-container {
  margin: 0;
}

.table-sub-heading {
  font-size: 16px;
  font-weight: bold;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders {
  min-height: 35px !important;
  max-height: 35px !important;
  line-height: 35px !important;
}

.pdf-table-sub-heading {
  text-align: left !important;
  font-size: 14px !important;
}

#pdf-table-no-img {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

#pdf-table-no-img td {
  border: 2px solid #ddd;
  font-size: 12px;
  padding: 20px;
  text-align: center;
}

#upload-image {
  color: red;
}

.required {
  float: left;
}

.required:after {
  content: " *";
  color: red;
}

.upload-file-wrap svg {
  width: 35px;
  height: 35px;
  color: #26c6da;
}

.upload-file-wrap p {
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0;
  margin-top: 5px;
}

.file-validation-msg {
  font-size: 13px;
  color: #ff0000;
}

.document-wrap .MuiBox-root {
  height: auto;
}

.document-wrap .table-no-row-found .MuiDataGrid-virtualScroller,
.document-wrap .table-no-row-found .MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapperInner {
  height: 60px !important;
}

.select-tab {
  padding: 30px 35px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  background: #fff;
}

.select-tab .MuiSelect-select {
  padding-right: 0px !important;
}

.doc-select-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.select-tab label {
  padding: 8px 0px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.select-tab .upload-file-wrap {
  margin: 15px 0px;
  padding: 40px 15px;
  background-color: #fafafa;
}

.select-tab .disabled-btn {
  color: #fff !important;
  background-color: #26c6da !important;
  cursor: not-allowed !important;
  border-color: #26c6da;
  opacity: 0.65;
  padding: 5px 14px;
  line-height: 2;
}

#leasingActivity th {
  font-weight: normal;
}

/****************responsive--style*****************/
/* tablets view (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dashboard-table-wrapper .MuiDataGrid-columnHeader {
    width: 110px !important;
    max-width: 110px !important;
  }

  .dashboard-table-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    width: 110px !important;
    max-width: 110px !important;
  }
}

.dashboard-dialog-box .MuiPaper-root {
  padding: 5px;
  width: 50%;
}

.dashboard-dialog-box .MuiDialogTitle-root {
  padding: 15px 15px 5px 15px;
  border-bottom: 1px solid #ddd;
}

.dashboard-dialog-box .MuiDialogActions-root {
  padding: 0px 15px 10px 15px;
}

.dashboard-dialog-box .MuiDialogActions-root .MuiButton-root {
  font-size: 13px;
  line-height: 1.8;
  padding: 4px 10px;
}

.common-dialog-box .MuiPaper-root {
  padding: 5px;
  width: 50%;
}

.common-dialog-box .MuiDialogTitle-root {
  padding: 15px 15px 5px 15px;
  border-bottom: 1px solid #ddd;
}

.common-dialog-box .MuiDialogContent-root {
  padding: 15px !important;
}

.common-dialog-box .MuiDialogActions-root {
  padding: 15px;
  border-top: 1px solid #ddd;
}

.common-dialog-box .MuiDialogActions-root .MuiButton-root {
  font-size: 13px;
  line-height: 1.8;
  padding: 4px 10px;
}

.audit-log .MuiBox-root {
  padding: 0;
}

/* mobile view (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

  /* for popup screens  */
  .dashboard-dialog-box .MuiPaper-root {
    padding: 5px;
    margin: 22px;
    width: 100%;
  }

  .common-dialog-box .MuiPaper-root {
    padding: 5px;
    margin: 22px;
    width: 100%;
  }

  /* for header screen */
  .header {
    padding: 12px;
  }

  .navbar {
    padding-left: 10px;
    padding-right: 10px;
  }

  .app-title {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: -10px;
    padding: 0;
  }

  .app-title span {
    margin-top: -15px;
  }

  .app-title .logo {
    background-size: 155px 32px;
  }

  .main-container .MuiContainer-root {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-right button {
    margin-right: auto;
    font-size: 14px;
  }

  .header-right .MuiAvatar-root {
    margin: 5px !important;
    width: 32px !important;
    height: 32px !important;
    font-size: 13px !important;
  }

  /* for dashboard table screen */
  .dashboard-table-wrapper .MuiDataGrid-columnHeader {
    width: 110px !important;
    max-width: 110px !important;
  }

  .dashboard-table-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    width: 110px !important;
    max-width: 110px !important;
  }

  .MuiDataGrid-panel,
  .MuiPaper-root,
  .MuiDataGrid-panelWrapper {
    width: 98%;
  }

  .MuiDataGrid-filterForm,
  .MuiDataGrid-panelFooter {
    padding: 2px !important;
  }

  /* for head action button */
  .head-action-btn {
    display: flex;
    flex-wrap: wrap;
  }

  .head-action-btn .MuiStack-root {
    margin-bottom: 10px;
  }

  .head-section {
    padding: 1px 10px 10px 10px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  /* for financials table screen */
  .responsive-table-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .responsive-table {
    display: table-cell !important;
    overflow-x: auto;
    width: 100%;
  }

  /* for valuations table screen */
  .data-grid-table .MuiDataGrid-columnHeader {
    width: 150px !important;
    max-width: 180px !important;
  }

  .data-grid-table .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    width: 150px !important;
    max-width: 180px !important;
  }

  .document-wrap .MuiDataGrid-columnHeader {
    width: 120px !important;
    max-width: 180px !important;
  }

  .document-wrap .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    width: 120px !important;
    max-width: 180px !important;
  }

  .dashboard-wrap .MuiTabs-root {
    min-height: unset;
  }

  .dashboard-wrap .MuiTabs-root .MuiButtonBase-root {
    min-height: 36px;
  }

  /* body {
    background: green !important;
  } */
}

/* for mobile view  */
.MuiDataGrid-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  padding: 1px !important;
}

/******for cloning data grid******/
.clone-data-grid .MuiTableHead-root .MuiTableCell-root {
  background: #253746;
  color: #fff;
}

.clone-data-grid .MuiTableCell-root {
  padding: 8px;
}