.custom-data-grid .MuiDataGrid-columnSeparator {
  display: none;
}

.custom-data-grid .MuiDataGrid-columnHeaderTitleContainer {
  height: 30px; /* Reduce the height of the column headers */
}
.custom-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeader {
  min-height: 30px !important;
max-height: 30px !important;
line-height: 30px !important;
}

/* Reduce the height of the MuiDataGrid header */
.custom-data-grid .css-1iyq7zh-MuiDataGrid-columnHeaders {
 min-height: 30px !important;
max-height: 30px !important;
line-height: 30px !important;
}

.custom-data-grid .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01071em;
  color: white;
  font-size: 14px;
  line-height: 20px!important;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  padding: 0 5px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-color: rgba(224, 224, 224, 1);
  height: 400px;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  /* min-height: 30px !important;
max-height: 30px !important;
line-height: 30px !important; */
}
.custom-data-grid .MuiTablePagination-selectRoot {
  display: none !important;
}
.custom-data-grid .MuiDataGrid-columnHeader {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01071em;
  outline: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
  background-color: #253746;
  color: white;
  font-size: 14px;
  height: 20px!important;
  line-height: 20px!important;
  padding: 0px;
  border-color: rgba(224, 224, 224, 1);
  min-height: 30px !important;
max-height: 30px !important;
line-height: 30px !important;
color: white !important;
}
/* .custom-data-grid .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.01071em;
  outline: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
  border-color: rgba(224, 224, 224, 1);
  min-height: 30px;
  max-height: 30px;
  line-height: 30px;
} */



.custom-data-grid .MuiDataGrid-columnHeaderCell {
  height: 30px !important; /* Reduce the height of the header row */
  min-height: 30px;
  max-height: 30px;
  line-height: 30px;
}

.custom-data-grid .MuiDataGrid-colCell:not(.MuiDataGrid-colCellSorted) {
  border-color: transparent; /* Remove the border color for non-sorted columns */
}

.custom-data-grid .MuiDataGrid-columnHeaderDraggableContainer {
  padding: 0px;
  margin: 0px; /* Adjust the spacing as needed */
  height: 30px; /* Set the height of the draggable container to 0 */
}

.custom-data-grid .MuiDataGrid-columnHeaderRow {
  height: 30px; /* Set the desired height for the header row */
  min-height: 30px;
  max-height: 30px;
  line-height: 30px;
}

.custom-data-grid .MuiDataGrid-root {
  border: 1px solid black; /* Change the border style */
}

.custom-data-grid .MuiDataGrid-cell {
  border-right: 1px solid #ddd; /* Border for columns */
}

.custom-data-grid .last-column {
  flex: 1 !important;
}



.custom-data-grid .MuiDataGrid-colCell {
  height: 20px; /* Set the desired height for the header row */
}

.custom-data-grid .MuiDataGrid-row {
  border-bottom: 1px
}

