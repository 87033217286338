.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.text-center {
  text-align: center;
}

.occupancy-container {
  width: 100%;
  height: 180px; /* Adjust the height as needed */
  overflow-y: auto; /* Add scrollbars if the content exceeds the height */
  margin-top: -15px; /* Adjust the top margin as needed */
}

.note {
  font-size: 14px;
  text-align: center;
  color: gray;
  max-width: 500px; /* Adjust the maximum width as needed */
  margin-top: 0px; /* Adjust the top margin as needed */
  margin-bottom: 0; /* Remove the bottom margin */
}
