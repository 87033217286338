* {
  outline: 0;
}

.App {
  font-family: sans-serif;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

input {
  display: block;
  min-width: 90%;
  margin: 1em;
  padding: 1em;
  width: 35em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
}

input:hover {
  border-color: palevioletred;
}

button {
  margin: 0.8em;
  padding: 1em;
  border: 1px solid #e4e6e8;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in;
}

button:hover {
  background-color: palevioletred;
  color: white;
}

.remove-image {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 28px;
  height: 27px;
  background-color: #ccc;
  cursor: pointer;
  color: #333;
  z-index: 999;
  border-radius: 4px;
}
.capitalPlanYearDropDown{
  width: 200px;
  margin:10px 0;
}