.table-container {
    margin: 16px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
 
  .table-header-cell {
    font-weight: bold;
    background-color: #f5f5f5;
    padding: 0px;
  }
  
  .table-label-cell {
    width: 70%;
    font-weight: bold;
    padding: 0px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  
  .table-data-cell {
    padding: 0px;
    border: 1px solid #ddd;
  }
  
  .table-data-cell input {
    width: 100%;
    padding: 0px;
    border: none;
    box-sizing: border-box;
    margin: 0;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f5f5f5; /* Grey background color for alternate rows */
  }
  

  